<template>
	<img :id="id" ref="background" class="background-image" :src="image" alt="" />
</template>

<script>
export default {
	props: {
		image: {
			type: String,
			required: true
		},
		id: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped lang="scss">
	.background-image {
		position: fixed;
		top: 0;
		right: 0;
		width: min-content;
		min-width: 100%;
		height: 100%;
		transition: 1s;
		z-index: 0;
	}

	@media (max-width: 768px) {
		.background-image {
			right: -20% !important;
			transition: 1s !important;
		}
	}
</style>