<script setup>
import background_image from "@/assets/media/bg-img.jpg";
import Logo from "@/assets/media/Logo.svg";
import facebook from "@/assets/media/icons/Facebook.svg";
import instagram from "@/assets/media/icons/Instagram.svg";
import linkedin from "@/assets/media/icons/LinkedIn.svg";
import youtube from "@/assets/media/icons/YouTube.svg";
import vimeo from "@/assets/media/icons/Vimeo.svg";
</script>
<template>
	<main>
		<img id="background-image" ref="background" class="background-image" :src="background_image" alt="" />
		<div class="container">
			<div ref="post" class="post">
				<div class="logo">
					<img class="img-logo" :src="Logo" alt="logo" />
				</div>
				<div class="slogan">
					a part of
					<span>THIS <span>IS</span> IDEAL</span>
				</div>
				<div class="title">
					We’re approaching<br />
					to launch our<br />
					<span>new website.</span>
				</div>
				<div ref="button" class="button">
					<div class="content">
						<svg>
							<clipPath id="mask-path">
								<rect width="100%" height="100%" />
							</clipPath>
						</svg>
						<div class="btn" @click="openLink">
							VISIT THIS IS IDEAL
						</div>
					</div>
					<div id="slider" ref="slider" class="slider">
						<div class="text">
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
						</div>
						<div class="text copy">
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
							<p>stay ready to dare to dreams</p>
							•
						</div>
					</div>
				</div>
				<div class="footer-title">
					Follow us on
				</div>
				<div class="footer-icons">
					<img :src="facebook" alt="facebook" @click="openSocialMedia('https://www.facebook.com/thisisideal.group/')"/>
					<img :src="instagram" alt="instagram" @click="openSocialMedia('https://www.instagram.com/thisisideal/')"/>
					<img :src="linkedin" alt="linkedin" @click="openSocialMedia('https://www.linkedin.com/company/this-is-ideal/?originalSubdomain=it')"/>
					<img :src="youtube" alt="youtube" @click="openSocialMedia('https://www.youtube.com/@thisisideal1120')"/>
					<img :src="vimeo" alt="vimeo" @click="openSocialMedia('https://vimeo.com/user36539071')"/>
				</div>
				<a class="footer-contact-us" href="mailto:emanuele.bertazzoni@idealcomunicazione.it?cc=emilio.araldi@idealcomunicazione.it">
					Contact us
				</a>
			</div>
		</div>
	</main>
	<div ref="loader" class="loader" v-if="!loaded"></div>
</template>

<script>
import BackgroundImage from "@/components/BackgroundImage.vue";
export default {
	components: {BackgroundImage},
	data() {
		return {
			loaded: false
		}
	},
	methods: {
		openLink() {
			window.open('https://www.thisisideal.com/', '_blank');
		},
		openSocialMedia(url) {
			window.open(url, '_blank');
		}
	},
	mounted() {
		this.$refs.background.style.width = `${1600}px`;
		setTimeout(() => {
			this.$refs.loader.classList.add('fadeOut');
			setTimeout(() => {
				this.loaded = true;
			}, 1200);
		}, 500);
	}
}
</script>

<style scoped lang="scss">
.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #4E537F;
	z-index: 99;

	&.fadeOut {
		transition: 1s;
		opacity: 0;
		pointer-events: none;
	}
}
</style>

<style scoped lang="scss">
.background-image {
	position: fixed;
	top: 0;
	right: 0;
	width: min-content;
	min-width:100%;
	height: 100%;
	transition: 1s;
	z-index: 0;
}

@media (max-width: 768px) {
	.background-image {
		transition: 1s !important;
		right: 0 !important;
	}
}
</style>

<style scoped lang="scss">
	main {
		width: 100%;
		height: 100%;

		.container {
			margin: 0 auto;
			width: 90%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: right;
			transition: 1s;

			.post {
				position: relative;
				margin: 0 40px;
				padding: 20px 0;
				width: fit-content;
				min-width: 350px;
				height: fit-content;
				display: flex;
				align-items: center;
				flex-direction: column;
				gap: 30px;
				z-index: 2 !important;
				backface-visibility: hidden;

				svg {}
			}

			/* .slider {
				font-family: "Sharp Grotesk", sans-serif !important;
				padding: 40px 0;
				position: fixed;
				left: 50%;
				transform: translateX(-50%) rotate(5deg);
				display: flex;
				align-items: center;
				justify-content: center;
				width: min-content;
				gap: 10px;
				font-weight: bold;
				font-size: 1.25rem;
				text-transform: uppercase;
				color: white;
				background-color: rgba(255, 255, 255, 0.1);
				backdrop-filter: blur(10px);
				z-index: 1;

				p {
					margin: 0;
					text-wrap: nowrap;
				}
			} */
		}
	}

	@media (max-width: 650px) {
		main {
			.container {
				justify-content: center !important;
				transition: 1s !important;

				.post {
					margin: 0 !important;
				}
			}
		}
	}
</style>

<style scoped lang="scss">
@font-face {
	font-family: 'SharpGrotesk';
	src: url(@/assets/fonts/SharpGrotesk-Medium25.otf) format('opentype');
}

.footer-title {
	font-weight: 400;
	font-size: 1.25rem;
	color: white;
	transition: .5s;
}

.footer-icons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;

	img {
		cursor: pointer;
		transition: .1s;

		&:hover {
			transition: .2s;
			opacity: .7;
		}
	}
}

.footer-contact-us {
	font-weight: 700;
	font-size: .9rem;
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	transition: .1s;

	&:hover {
		transition: .2s;
		opacity: .7;
	}
}

.logo {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slogan {
	color: white;
	font-weight: 400;
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 13px;
	font-size: 0.87rem;

	span {
		font-size: 1.25rem;
		text-transform: uppercase;
		font-weight: lighter;

		span {
			font-weight: 700;
		}
	}
}

.title {
	font-family: "SharpGrotesk", sans-serif !important;
	padding: 10px 0;
	line-height: 1.33;
	text-align: center;
	font-weight: 500;
	color: #ffffff;
	font-size: 2.25rem;
	letter-spacing: -0.7px;
	transition: .5s;

	span {
		font-family: "SharpGrotesk", sans-serif !important;
		color: #F0D1B0 !important;
	}
}

@media (max-width: 768px) {
	.logo {
		img {
			width: 80% !important;
		}
	}

	.title {
		font-size: 1.5rem;
		transition: .5s;
	}

	.button {
		font-size: 0.8rem !important;
	}
}

.button {
	position: relative;
	z-index: -1;

	.content {
		position: relative;
		z-index: 1;

		.btn {
			cursor: pointer;
			font-family: "SharpGrotesk", sans-serif !important;
			border: 1px solid #ffffff;
			padding: 1.12rem 1.1rem;
			text-transform: uppercase;
			background-color: #4E537F;
			color: white;
			font-weight: 500;
			font-size: 1.12rem;
			transition: .1s;
			z-index: 1;

			&:hover {
				transition: .3s;
				background-color: white;
				color: #4E537F;
			}
		}

		svg {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scaleY(3) rotate(7deg);
			position: absolute;
			z-index: -1;
			width: 140%;
			height: 120%;
			background-color: #505380;

			#mask-path {
				transform: rotate(5deg);
			}
		}
	}

	.slider {
		font-family: "Sharp Grotesk", sans-serif !important;
		padding: 40px 0;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%) translateY(-20%) rotate(5deg);
		display: flex;
		align-items: center;
		justify-content: center;
		width: min-content;
		gap: 10px;
		font-weight: bold;
		font-size: 1.25rem;
		text-transform: uppercase;
		color: white;
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(10px);
		z-index: -1;

		.text {
			width: 100%;
			height: 100%;
			display: flex;
			gap: 10px;
			align-items: center;
			animation: scroll 40s linear infinite;

			.copy {
				padding-left: 100%;
			}
		}

		p {
			margin: 0;
			text-wrap: nowrap;
		}
	}
}

@media (max-width: 768px) {
	.button {
		.content {
			.btn {
				padding: 0.8rem 1rem;
				font-size: 0.8rem;
				transition: .1s;
				z-index: 1;
			}
		}

		.slider {
			display: none;
			font-size: 0.8rem;
			transition: .1s;
			z-index: -1;
		}
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}

</style>